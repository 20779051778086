@import 'Styles/breakpoints.module.scss';

.facetContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.facetBlock {
  margin-bottom: 1rem;
}
