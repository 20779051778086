.contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-top: 1rem;
  }
  margin-bottom: 1rem;
  width: 100%;
}

.resultText {
  display: inline-flex;
}

.whitespace {
  padding-left: 2px;
  padding-right: 2px;
}
