.prp_layout_sec {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prp_main {
  display: flex;
  flex-wrap: wrap;

  .prp_layout_sidebar {
    width: 100%;
    overflow: hidden;
    display: none;
  }
  .prp_layout_spacer {
    width: 100%;
  }
  .prp_layout_results {
    width: 100%;
    padding-bottom: 1rem;
  }
}

.prp_layout_extended {
  width: 100%;
  padding-top: 2rem;
}

.stickyHeaderPrp {
  padding: 0.25rem 0;
}

@media (min-width: 835px) {
  .prp_main {
    scroll-margin-top: 2rem;
    flex-wrap: nowrap;
    .prp_layout_sidebar {
      // position: sticky;
      align-self: flex-start;
      top: 1vh;
      //prevent collision with mobile menu bar
      // max-height: 90vh;
      // overflow-y: auto;
      display: block;
      width: 30%;
    }

    .prp_layout_spacer {
      width: 1%;
    }

    .prp_layout_results {
      //position: sticky;
      align-self: flex-start;
      top: 2rem;
      padding-top: 0;
      padding-bottom: 0;
      width: 69%;

      display: flex;
      flex-direction: row;

      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-around;
      align-items: center;
    }
  }
}

@media (min-width: 1024px) {
  //mobile menu bar gone, time to partay
  .prp_main {
    .prp_layout_sidebar {
      // max-height: 98vh;
      max-width: 320px;
    }
  }
}

@media (min-width: 1336px) {
  .prp_main {
    .prp_layout_sidebar {
      width: 25%;
      max-width: 320px;
      display: block;
      overflow: visible;
      //max-height: calc(100vh - 1rem);
      // overflow-y: scroll;
    }
    .prp_layout_spacer {
    }
    .prp_layout_results {
      width: 72%;
    }
  }
}

.prp_layout_noresults {
  width: 100%;
  padding-bottom: 1rem;
}
