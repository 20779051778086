.hideMobile {
  display: none;
}

.showMobile {
  display: block;
}

.categoriesTitle {
  display: none;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: break-spaces;
  width: 90px;
  text-align: center;
  margin-top: 1rem;
}

.image {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.noImagesMobileContainer {
  margin-bottom: 1rem;
}

.topCategories {
  width: calc(100vw);
  margin-left: -0.5rem;
  margin-bottom: 1rem;
}

.spacer {
  width: 0.5rem;
}

.topCategoryWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}

.topCategoryCard {
  box-sizing: border-box;
  display: flex;
  margin-top: 0;
  height: 80px;
  width: 100%;
  min-width: 270px;
}

.noImageCard {
  height: 40px;
  min-width: unset;
}

.topCategoryTitle {
  margin-bottom: 0.25rem;
}

.topCategoryCardContent {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  mix-blend-mode: multiply;
  padding: 0 1.5rem;
  box-sizing: border-box;
}

.skeleton {
  height: 80px;
}

.noWrap,
.totalNoWrap {
  white-space: nowrap;
}

@media (min-width: 768px) and (max-width: 799px) {
  .topCategories {
    left: -2rem;
  }

  .spacer {
    width: 2rem;
  }
}

@media (min-width: 800px) {
  .topCategoryCard {
    min-width: unset;
  }
  .topCategoriesMobileContainer {
    position: static;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    width: 100%;
  }

  .topCategories {
    display: block;
    width: 100%;
    position: static;
  }

  .noWrap {
    white-space: unset;
  }

  .spacer {
    display: none;
  }

  .hideMobile {
    display: block;
  }
}
