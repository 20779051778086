.divider {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.switchLabel {
  margin-left: 0.5rem;
}

.cursor {
  cursor: pointer;
}

.facetGroup {
  display: flex;
  flex-direction: column;
}

.h2 {
  display: none;
}

.form {
  width: 100%;
}
