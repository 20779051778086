.card {
  display: none;
}

@media (min-width: 768px) {
  .card {
    display: inline;
    margin-left: 0.5rem;
    padding: 0.5rem;
  }

  .scoresWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
  }

  .score {
    padding: 0.1rem 0.25rem;
    overflow: hidden;
    width: 100%;
  }

  .matchedFields {
    padding: 0.1rem 0.25rem;
  }
}
