.title {
  margin-top: 1rem;
}

.sectionWrapper {
  margin-bottom: 1rem;
}

.linkWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 1rem;
}

.linkCard {
  min-width: 16rem;
}

.link {
  display: flex;
  justify-content: space-between;
  min-width: 16rem;
}

@media (min-width: 1670px) {
  .linkWrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1354px) and (max-width: 1669px) {
  .linkWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 974px) and (max-width: 1353px) {
  .linkWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 630px) and (max-width: 973px) {
  .linkWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 629px) {
  .linkWrapper {
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-around;
  }
  .linkCard {
    justify-self: center;
  }
}
