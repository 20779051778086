.compareToolWrapper {
  max-width: 90vw;
  width: 100%;
  height: 100%;
  max-height: 75vh;
}

@media (min-width: 1000px) {
  .compareToolWrapper {
    max-width: 75vw;
    width: 100%;
    height: 100%;
    max-height: 75vh;
  }
}
