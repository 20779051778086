.divider {
  margin-top: 0.75rem;
  margin-bottom: 0.875rem;
}

.chips {
  position: relative;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 1.5rem;
    right: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(90deg, var(--i), var(--b));
    pointer-events: none;
  }
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.fadeout {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
}
.fadeout.noResultPage {
  flex-wrap: wrap;
  justify-content: center;
}
.fadeout::-webkit-scrollbar {
  display: none;
}

@media (min-width: 835px) {
  .chips::after {
    display: none;
  }
  .wrapperMargin {
    margin-top: 0.5rem;
  }
}

@media (max-width: 835px) {
  .hideMobile {
    display: none;
  }
  .wrapperMargin {
    flex-wrap: wrap;
    display: flex;
    align-content: center;
  }
}

.chip_divider {
  border-right-style: solid;
  border-right-width: 1px;
  padding-right: 0.75rem;
  margin-right: 0.75rem;
}
.chip_label {
  margin-right: 0.25rem;
}
.chip_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
}

.chip {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0;
  border-width: 1px;
  border-radius: 50px;
  border-style: solid;
}

.chip_contents {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.chip_value {
  margin-right: 0.75rem;
}
