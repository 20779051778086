.option {
  position: relative;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;

  -webkit-transition: background-color 200ms ease-in-out; /* Safari prior 6.1 */
  transition: background-color 200ms ease-in-out;

  cursor: pointer;
}

.option_name {
  left: 0;
  right: 0;
  bottom: 0.25rem;
  text-align: center;
}

.optionImage {
  min-width: 88px;
  mix-blend-mode: multiply;
}

.link {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
