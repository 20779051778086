.scroll {
  display: flex;
  align-items: center;
  margin-left: 7.75rem;
  overflow-x: auto;
}

.facetSelected {
  margin-left: 10rem;
}

.onlyChips {
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: wrap;
}

.chip {
  display: flex;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  margin-right: 0.75rem;
  border-width: 1px;
  border-radius: 50px;
  border-style: solid;
  align-items: center;
}

.chip_contents {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.chip_value {
  margin-right: 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
}

.vehicleSelectIcon {
  margin-top: -1px;
}
