.spacer,
.js-maker-enhance-wrapper {
  width: 100%;
  aspect-ratio: 320 / var(--m);
  overflow: hidden;
  animation-name: collapse;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.1s;
}

@keyframes collapse {
  from {
    aspect-ratio: initial;
  }

  to {
    aspect-ratio: auto;
  }
}

.pageTitleMobile {
  display: flex;
  align-items: baseline;
}

.resultsText {
  margin-right: 0.5rem;
  word-wrap: break-word;
  word-break: break-word;
}

.bannerContain,
.nonShopBannerContain {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  text-shadow:
    0 0 2px #333,
    0 0 2px #333,
    0 0 2px #333;
}

.bannerTextStack {
  max-width: 90vw;
  width: 100%;
}

.bannerGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.95));
}

.bannerContent {
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;

  & div {
    //preventing some default stuff in the breadcrumb
    padding: 0;
    direction: ltr;
  }
}

.breadcrumbPlaceholder {
  margin-top: 19px;
}

.bannerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerBrandImage {
  display: none;
}

.hideMobile {
  display: none;
}

@media only screen and (min-width: 600px) {
  .spacer,
  .js-maker-enhance-wrapper {
    aspect-ratio: 600 / var(--t);
  }
}

@media only screen and (min-width: 767px) {
  .bannerGradient {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.95)
    );
  }
  .bannerContain {
    height: 220px;
  }

  .nonShopBannerContain {
    height: 120px;
  }

  .hideMobile {
    display: flex;
  }
  .hideDesktop {
    display: none;
  }

  .bannerContent {
    padding-top: 0;
    padding-bottom: 0;
  }

  .bannerBrandImage {
    display: block;
    right: 1rem;
    width: 15rem;

    & img {
      filter: drop-shadow(0px 0px 5px #fff) drop-shadow(0px 0px 5px #fff);
    }
  }
}

@media only screen and (min-width: 1366px) {
  .spacer,
  .js-maker-enhance-wrapper {
    aspect-ratio: 1366 / var(--d);
  }
  .bannerContain {
    height: 220px;
  }
}

.headerContainer {
  display: flex;
  align-items: baseline;
  gap: 0.2rem;
  flex-wrap: wrap;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
}

.iconWrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: center;
  line-height: 1;
  position: relative;
  padding-left: 0.3rem;
}

.nrpText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
