.rule {
  padding: 0.1rem 0.25rem;
}

.card {
  display: inline-block;
}

.excludedWrapper {
  display: inline-block;
  cursor: pointer;
}

.popoverContent {
  padding: 0.5rem;
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.excludedGroup {
  margin-bottom: 1rem;
}

.excludedGroupTitle {
  margin-bottom: 0.5rem;
}

.excludedList {
  padding-left: 0;
  list-style-type: none;
}

.excludedItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.excludedInfo {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.excludedKey {
  font-weight: bold;
}

.excludedValue {
  color: red;
}
